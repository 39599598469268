/* eslint-disable react/no-array-index-key */
// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TextField from "@mui/material/TextField";
import { PDFViewer, Document, Page, Text, StyleSheet, Image, View } from "@react-pdf/renderer";
// Overview page components
import Header from "layouts/createinvoice/components/Header";

// React
import { useEffect, useState, Fragment } from "react";

// Firebase
import {
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { db } from "firebaseDb";

import JsBarcode from "jsbarcode";

function BinCards() {
  const [startNumber, setStartNumber] = useState(0);
  const [endNumber, setEndNumber] = useState(0);
  const [supervisors, setSupervisors] = useState([]); // List of supervisors
  const [selectedSupervisor, setSelectedSupervisor] = useState("");
  const [color, setcolor] = useState("#FFFFFF");
  const [newSupervisorName, setNewSupervisorName] = useState("");
  const [showPDF, setShowPDF] = useState(false);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [binCards, setBinCards] = useState([]);
  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [ownPickers, setOwnPickers] = useState(false);
  const [newownPickers, setNewOwnPickers] = useState(false);

  // Styles for the PDF document
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      padding: 10,
      paddingBottom: 0, // Reduce bottom padding
      paddingTop: 10, // Reduce bottom padding
      marginTop: 10,
    },
    section: {
      marginBottom: 1,
    },
    expandableSpace: {
      flexGrow: 1, // Takes up remaining space
    },
    labelBox: {
      borderWidth: 1,
      borderColor: "#000",
      padding: 0,
      margin: 0,
      flexGrow: 1,
      justifyContent: "flex-end", // Move content to the bottom
      alignItems: "center",
      height: 50, // Ensure enough height for proper positioning
    },
    labelBoxTop: {
      borderWidth: 1,
      borderColor: "#000",
      padding: 0,
      margin: 0,
      flexGrow: 1,
      justifyContent: "flex-end", // Move content to the bottom
      alignItems: "center",
      height: 90, // Ensure enough height for proper positioning
    },
    labelBoxBottom: {
      borderWidth: 3,
      borderColor: "#000",
      padding: 0,
      margin: 0,
      flexGrow: 1,
      justifyContent: "flex-end", // Move content to the bottom
      alignItems: "center",
      height: 81, // Ensure enough height for proper positioning
    },
    labelBoxBarcode: {
      borderWidth: 3,
      borderRightWidth: 40,
      borderLeftWidth: 40,
      borderColor: "#000",
      padding: 0,
      margin: 0,
      flexGrow: 1,
      justifyContent: "flex-end", // Move content to the bottom
      alignItems: "center",
      height: 81, // Ensure enough height for proper positioning
    },
    labelBoxSupervisor: {
      borderWidth: 1,
      borderColor: "#000",
      padding: 0,
      margin: 0,
      flexGrow: 1,
      justifyContent: "flex-end", // Move content to the bottom
      alignItems: "center",
      height: 50, // Ensure enough height for proper positioning
      backgroundColor: color,
    },
    labelText: {
      fontSize: 10,
      textAlign: "center",
      marginTop: 5, // Optional: Add spacing between the content and the label
    },
    valueText: {
      fontSize: 30,
      textAlign: "center",
    },
    topSection: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 1,
    },
    middleSection: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    grid: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    gridSection: {
      flexDirection: "row",
      flexWrap: "wrap", // Allow boxes to wrap onto the next row
    },
    gridBox: {
      height: 81,
      width: "125", // 100% divided by 5 columns
      borderWidth: 1,
      borderColor: "#000",
      justifyContent: "flex-end", // Align the number at the bottom
      alignItems: "center",
      padding: 5,
    },
    noLeftBorder: {
      borderLeftWidth: 0, // Removes left border
      width: "100px", // 100% divided by 5 columns
    },
    noRightBorder: {
      borderRightWidth: 0, // Removes right border
      width: "100px", // 100% divided by 5 columns
    },
    gridBoxNull: {
      height: 81,
      width: "100px", // 100% divided by 4 columns
      borderWidth: 10,
      borderColor: "#000",
      justifyContent: "flex-end", // Align the number at the bottom
      alignItems: "center",
      padding: 5,
    },
    gridBoxText: {
      fontSize: 10,
      textAlign: "center",
    },
    gridBoxTextNull: {
      fontSize: 20,
      textAlign: "center",
    },
    thickLine: {
      width: "100%", // Full width of the grid section
      height: 4, // Thickness of the line
      backgroundColor: "#000", // Line color
      marginVertical: 0, // Optional spacing around the line
    },
  });

  // Fetch supervisors from Firebase
  useEffect(() => {
    setLoading(true);
    const supervisorsRef = collection(db, "Supervisors");

    const unsubscribe = onSnapshot(supervisorsRef, (snapshot) => {
      const supervisorList = snapshot.docs.map((docu) => ({
        id: docu.id,
        ...docu.data(),
      }));
      setSupervisors(supervisorList);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Handle supervisor selection
  const handleSupervisorChange = (e) => {
    const selected = e.target.value;
    setSelectedSupervisor(selected);
    const supervisor = supervisors.find((sup) => sup.name === selected);
    if (supervisor) {
      setcolor(supervisor.color);
    }
    if (supervisor) {
      setMin(supervisor.start);
    }
    if (supervisor) {
      setMax(supervisor.end);
    }
    if (supervisor) {
      setOwnPickers(supervisor.ownPickers);
    }
  };

  // Handle adding a new supervisor
  const handleAddSupervisor = async () => {
    if (!newSupervisorName.trim()) {
      alert("Please enter a supervisor name.");
      return;
    }
    if (!startRange.trim()) {
      alert("Please enter a starting range.");
      return;
    }
    if (!endRange.trim()) {
      alert("Please enter an ending range.");
      return;
    }
    try {
      await addDoc(collection(db, "Supervisors"), {
        name: newSupervisorName,
        color: "#FFFFFF", // Default color
        start: startRange,
        end: endRange,
        ownPickers,
      });
      setNewSupervisorName("");
      setStartRange(0);
      setEndRange(0);
      setOwnPickers(false);
    } catch (error) {
      console.error("Error adding supervisor:", error);
    }
  };

  // Handle saving supervisor color updates
  const handleSaveValues = async () => {
    console.log(ownPickers);
    if (!selectedSupervisor) {
      alert("Please select a supervisor to save changes.");
      return;
    }
    try {
      const supervisor = supervisors.find((sup) => sup.name === selectedSupervisor);
      const supervisorDoc = doc(db, "Supervisors", supervisor.id);
      await updateDoc(supervisorDoc, { color, start: min, end: max, ownPickers });
      alert("Supervisor details updated successfully.");
    } catch (error) {
      console.error("Error updating supervisor:", error);
    }
  };

  if (loading) {
    return (
      <Box mt={5} mb={3} textAlign="center">
        <CircularProgress color="success" />
      </Box>
    );
  }

  // Generate page numbers and barcodes for the PDF
  const handleGeneratePDF = async () => {
    // Reset pages to clear any previous ones
    setPages([]);

    const start = parseInt(startNumber, 10);
    const end = parseInt(endNumber, 10);

    if (Number.isNaN(start) || Number.isNaN(end) || start > end) {
      alert("Please enter valid numbers with Start <= End.");
      return;
    }

    const rangeToGenerate = Array.from({ length: end - start + 1 }, (_, i) => start + i);

    try {
      const docRef = doc(db, "BinCards", "generatedNumbers"); // Replace with your Firestore path
      const docSnap = await getDoc(docRef);

      let existingNumbers = [];
      if (docSnap.exists()) {
        existingNumbers = docSnap.data().numbers || [];
      }

      // Check for overlap
      const overlap = rangeToGenerate.some((num) => existingNumbers.includes(num));
      if (overlap) {
        const firstConfirmation = window.confirm(
          "The numbers chosen overlap with existing numbers. Do you want to proceed?"
        );
        if (!firstConfirmation) {
          return; // Exit if the user cancels the first confirmation
        }

        const secondConfirmation = window.confirm(
          "Are you SURE you want to reprint these? Reprinting bin cards will cause major issues."
        );
        if (!secondConfirmation) {
          return; // Exit if the user cancels the second confirmation
        }

        const thirdConfirmation = window.confirm(
          "Just to double-check... you know what you're doing?"
        );
        if (!thirdConfirmation) {
          return; // Exit if the user cancels the third confirmation
        }
      }

      // Save new numbers to Firebase
      await updateDoc(docRef, {
        numbers: arrayUnion(...rangeToGenerate),
      });

      // Generate barcodes for the new range
      const barcodes = rangeToGenerate.map((number) => {
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, number.toString(), { format: "CODE128" });
        return canvas.toDataURL("image/png");
      });

      // Now set the pages with the new data
      setPages(rangeToGenerate.map((number, index) => ({ number, barcode: barcodes[index] })));
      setShowPDF(true);

      alert("Numbers generated and saved successfully.");
    } catch (error) {
      console.error("Error generating numbers:", error);
      alert("An error occurred while generating numbers. Please try again.");
    }
  };

  const handleShowBinCards = async () => {
    setLoading(true);
    try {
      const docRef = doc(db, "BinCards", "generatedNumbers"); // Replace with your Firestore path
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const numbers = docSnap.data().numbers || [];
        setBinCards(numbers.sort((a, b) => a - b)); // Update state with fetched numbers
      } else {
        alert("No bin cards found.");
      }
    } catch (error) {
      console.error("Error fetching bin cards:", error);
      alert("An error occurred while fetching bin cards. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleStartNumberChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= min || e.target.value === "") {
      setStartNumber(e.target.value);
    }
  };
  const handleEndNumberChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value <= max || e.target.value === "") {
      setEndNumber(e.target.value);
    }
  };
  // React-PDF document
  const MyDocument = (
    <Document>
      {pages.map(({ number, barcode }) => (
        <>
          <Page size="A4" style={styles.page} key={number}>
            {/* Top Section: Grower, Block, Date, Barcode */}
            <View style={styles.topSection}>
              <View style={styles.labelBoxTop}>
                <Text style={styles.labelText}>Grower</Text>
              </View>
              <View style={styles.labelBoxTop}>
                <Text style={styles.labelText}>Block</Text>
              </View>
              <View style={styles.labelBoxTop}>
                <Text style={styles.labelText}>Date</Text>
              </View>
              <View style={styles.labelBoxTop}>
                <Image src={barcode} style={{ width: 100, height: 80 }} />
              </View>
            </View>

            {/* Middle Section: Variety, Buckets, Supervisor */}
            <View style={styles.middleSection}>
              <View style={styles.labelBox}>
                <Text style={styles.labelText}>Variety</Text>
              </View>
              <View style={styles.labelBox}>
                <Text style={styles.labelText}>Buckets</Text>
              </View>
              <View style={styles.labelBoxSupervisor}>
                <Text style={styles.valueText}>{selectedSupervisor}</Text>
                <Text style={styles.labelText}>Supervisor</Text>
              </View>
            </View>

            {/* Conditionally Render Grid Sections */}

            {ownPickers && (
              <>
                {/* Top Section: Grower, Block, Date, Barcode */}
                <View style={styles.topSection}>
                  <View style={styles.labelBoxTop}>
                    <Text style={styles.labelText}>Grower</Text>
                  </View>
                  <View style={styles.labelBoxTop}>
                    <Text style={styles.labelText}>Block</Text>
                  </View>
                  <View style={styles.labelBoxTop}>
                    <Text style={styles.labelText}>Date</Text>
                  </View>
                  <View style={styles.labelBoxTop}>
                    <Image src={barcode} style={{ width: 100, height: 80 }} />
                  </View>
                </View>

                {/* Middle Section: Variety, Buckets, Supervisor */}
                <View style={styles.middleSection}>
                  <View style={styles.labelBox}>
                    <Text style={styles.labelText}>Variety</Text>
                  </View>
                  <View style={styles.labelBox}>
                    <Text style={styles.labelText}>Buckets</Text>
                  </View>
                  <View style={styles.labelBoxSupervisor}>
                    <Text style={styles.valueText}>{selectedSupervisor}</Text>
                    <Text style={styles.labelText}>Supervisor</Text>
                  </View>
                </View>
                {/* Expandable Space */}
                <View style={styles.expandableSpace} />
                {/* Top Section: Grower, Block, Date, Barcode */}
                <View style={styles.topSection}>
                  <View style={styles.labelBoxTop}>
                    <Text style={styles.labelText}>Grower</Text>
                  </View>
                  <View style={styles.labelBoxTop}>
                    <Text style={styles.labelText}>Block</Text>
                  </View>
                  <View style={styles.labelBoxTop}>
                    <Text style={styles.labelText}>Date</Text>
                  </View>
                  <View style={styles.labelBoxTop}>
                    <Image src={barcode} style={{ width: 100, height: 80 }} />
                  </View>
                </View>

                {/* Middle Section: Variety, Buckets, Supervisor */}
                <View style={styles.middleSection}>
                  <View style={styles.labelBox}>
                    <Text style={styles.labelText}>Variety</Text>
                  </View>
                  <View style={styles.labelBox}>
                    <Text style={styles.labelText}>Buckets</Text>
                  </View>
                  <View style={styles.labelBoxSupervisor}>
                    <Text style={styles.valueText}>{selectedSupervisor}</Text>
                    <Text style={styles.labelText}>Supervisor</Text>
                  </View>
                </View>
              </>
            )}
            {!ownPickers && (
              <>
                {/* Grid Section: First 24 Squares */}
                <View style={styles.gridSection}>
                  {Array.from({ length: 24 }, (_, i) => (
                    <Fragment key={i + 1}>
                      <View
                        style={[
                          styles.gridBox,
                          i % 5 === 0 && styles.noLeftBorder, // First box in the row
                          (i + 1) % 5 === 0 && styles.noRightBorder, // Last box in the row
                        ]}
                      >
                        <Text style={styles.gridBoxText}>{i + 1}</Text>
                      </View>
                    </Fragment>
                  ))}
                  <View style={[styles.gridBox, styles.gridBoxNull]}>
                    <Text style={styles.gridBoxTextNull}>Layer 1 End</Text>
                  </View>
                  <View style={styles.thickLine} />
                </View>

                {/* Grid Section: Next 12 Squares on First Page */}
                <View style={styles.gridSection}>
                  {Array.from({ length: 12 }, (_, i) => (
                    <Fragment key={i + 25}>
                      <View
                        style={[
                          styles.gridBox,
                          i % 5 === 0 && styles.noLeftBorder, // First box in the row
                          (i + 1) % 5 === 0 && styles.noRightBorder, // Last box in the row
                        ]}
                      >
                        <Text style={styles.gridBoxText}>{i + 25}</Text>
                      </View>
                    </Fragment>
                  ))}
                  <View style={styles.labelBoxBottom}>
                    <Text style={styles.labelText}>
                      Grower/Block/Variety -------- Bin:{number}/{selectedSupervisor}
                    </Text>
                  </View>
                </View>
              </>
            )}
          </Page>
          {/* Conditionally Render Grid Sections */}
          {!ownPickers && (
            <Page size="A4" style={styles.page} key={number}>
              {/* Grid Section: 12 Squares on Second Page */}
              <View style={styles.gridSection}>
                {Array.from({ length: 12 }, (_, i) => (
                  <Fragment key={i + 37}>
                    <View
                      style={[
                        styles.gridBox,
                        i % 5 === 0 && styles.noLeftBorder, // First box in the row
                        (i + 1) % 5 === 0 && styles.noRightBorder, // Last box in the row
                      ]}
                    >
                      <Text style={styles.gridBoxText}>{i + 37}</Text>
                    </View>
                  </Fragment>
                ))}
                <View style={[styles.gridBox, styles.gridBoxNull]}>
                  <Text style={styles.gridBoxTextNull}>Layer 2 End</Text>
                </View>
                <View style={styles.labelBoxBarcode}>
                  <Image src={barcode} style={{ width: 100, height: 80 }} />
                </View>
                <View style={styles.thickLine} />
                {/* Grid Section: Last 24 Squares on Second Page */}
                {Array.from({ length: 24 }, (_, i) => (
                  <Fragment key={i + 49}>
                    <View
                      style={[
                        styles.gridBox,
                        i % 5 === 0 && styles.noLeftBorder, // First box in the row
                        (i + 1) % 5 === 0 && styles.noRightBorder, // Last box in the row
                      ]}
                    >
                      <Text style={styles.gridBoxText}>{i + 49}</Text>
                    </View>
                  </Fragment>
                ))}
                <View style={[styles.gridBox, styles.gridBoxNull]}>
                  <Text style={styles.gridBoxTextNull}>Bin End</Text>
                </View>
              </View>
            </Page>
          )}
        </>
      ))}
    </Document>
  );

  if (!loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox mt={5} mb={3}>
          <Grid pl={8} container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label={`Start Number (Minimum: ${min})`}
                type="number"
                fullWidth
                value={startNumber}
                onChange={handleStartNumberChange}
                inputProps={{
                  min, // Enforces minimum number in the input field
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label={`End Number (Maximum: ${max})`}
                type="number"
                fullWidth
                value={endNumber}
                onChange={handleEndNumberChange}
                inputProps={{
                  max, // Enforces max number in the input field
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Supervisor"
                value={selectedSupervisor}
                onChange={handleSupervisorChange}
                fullWidth
                sx={{
                  ".MuiOutlinedInput-root": {
                    minHeight: "44px", // Ensure it matches the default TextField height
                  },
                }}
              >
                {supervisors.map((supervisor) => (
                  <MenuItem key={supervisor.id} value={supervisor.name}>
                    {supervisor.name}
                  </MenuItem>
                ))}
              </TextField>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ marginBottom: 2, marginTop: 2 }}>
                  <TextField
                    label="Color"
                    type="color" // Use color type
                    fullWidth
                    value={color} // Bind this to your state
                    onChange={(e) => setcolor(e.target.value)} // Update the state on change
                    InputLabelProps={{ shrink: true }} // Ensure the label remains visible
                  />
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    type="number"
                    label="Start Range"
                    value={min}
                    onChange={(e) => setMin(e.target.value)}
                    fullWidth
                  />
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    type="number"
                    label="End Range"
                    value={max}
                    onChange={(e) => setMax(e.target.value)}
                    fullWidth
                  />
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ownPickers}
                        onChange={(e) => {
                          setOwnPickers(e.target.checked); // Updates the state correctly
                        }}
                        color="primary"
                      />
                    }
                    label="Own Pickers"
                  />
                </Box>
                <MDButton variant="contained" color="dark" onClick={handleSaveValues}>
                  Save Values
                </MDButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MDButton
                size="xl"
                variant="contained"
                color="success"
                onClick={handleGeneratePDF}
                sx={{ backgroundColor: "#00000" }}
              >
                Generate PDF
              </MDButton>
            </Grid>
            {/* Add new supervisor */}
            <Box
              sx={{
                border: "2px solid #1976d2", // Outline color
                borderRadius: "8px", // Optional rounded corners
                padding: 2, // Space inside the box to maintain layout
                margin: 2,
                width: "100%",
              }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="New Supervisor"
                    value={newSupervisorName}
                    onChange={(e) => setNewSupervisorName(e.target.value)}
                    fullWidth
                  />
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    type="number"
                    label="Start Range"
                    value={startRange}
                    onChange={(e) => setStartRange(e.target.value)}
                    fullWidth
                  />
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    type="number"
                    label="End Range"
                    value={endRange}
                    onChange={(e) => setEndRange(e.target.value)}
                    fullWidth
                  />
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newownPickers}
                        onChange={(e) => {
                          setNewOwnPickers(e.target.checked); // Updates the state correctly
                        }}
                        color="primary"
                      />
                    }
                    label="Own Pickers"
                  />
                </Box>
              </Grid>
            </Box>

            <Grid item xs={12} sm={6} md={4}>
              <MDButton
                variant="contained"
                color="primary"
                onClick={handleAddSupervisor}
                sx={{ color: "#FFFF" }}
              >
                + Add Supervisor
              </MDButton>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <MDButton variant="contained" color="dark" onClick={handleShowBinCards}>
                View Existing Bin Cards
              </MDButton>
            </Grid>
          </Grid>
          {showPDF && (
            <MDBox mt={5}>
              <PDFViewer style={{ width: "100%", height: "500px", minHeight: "500px" }}>
                {MyDocument}
              </PDFViewer>
            </MDBox>
          )}
          {/* Title */}
          <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
            Generated Bin Cards
          </Typography>

          {/* Display the list of bin cards */}
          {binCards.length > 0 ? (
            <List>
              {binCards.map((number, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`Bin Card Number: ${number}`} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No bin cards available.
            </Typography>
          )}
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <Box mt={5} mb={3} textAlign="center">
          <CircularProgress color="success" />
        </Box>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default BinCards;
